.productLink {
  width: 100%;
  aspect-ratio: 1;

  &.displayScrollbar {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
  }

  @media (hover: none) {
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
      display: none !important;
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 var(--ds-border-width-xs) var(--ds-color-focused-default) !important;
  }

  &.nextButtonHidden [id='product-image-arrow-next'],
  &.prevButtonHidden [id='product-image-arrow-prev'] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    display: none;
  }

  & button {
    position: absolute;
    display: none;
    transform: opacity 0.3s linear;

    @media (hover: hover) and (pointer: fine) {
      display: flex; /* Skroll makes the icons flex children */
      opacity: 0;
    }

    &:focus-within {
      display: flex; /* Skroll makes the icons flex children */
      opacity: 1;
    }

    &[id='product-image-arrow-prev'] {
      left: var(--ds-spacing-4xs);
    }

    &[id='product-image-arrow-next'] {
      right: var(--ds-spacing-4xs);
    }
  }
}

@media (hover: hover) and (pointer: fine) {
  .productLink:hover button {
    opacity: 1;
  }
}

.carouselContainer {
  display: flex;
  flex: 0 0 calc(var(--images-count) * 100%);

  & li,
  & picture {
    width: calc(100% / var(--images-count));
    aspect-ratio: 1;
    padding-bottom: var(--ds-spacing-3xs);
    scroll-snap-align: center;
    scroll-snap-stop: always;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.progressBar {
  position: sticky;
  top: 100%;
  left: 0;
  width: 100%;
  height: var(--ds-spacing-4xs);
  opacity: 0.7;
  background: var(--ds-color-transparent-black-100);
  &::before {
    content: '';
    display: block;
    width: calc(100% / var(--images-count));
    background: var(--ds-color-transparent-black-1000);
    height: 100%;
    transform: translateX(calc(100% * var(--offset)));
    transition: transform 0.2s linear;
  }

  @media (hover: hover) and (pointer: fine) {
    display: none;
  }
}
