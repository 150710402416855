.wrapper {
  display: flex;
}

.wrapper.daysFormat {
  align-items: flex-end;
}

.wrapper.daysFormat .label {
  margin-left: var(--ds-spacing-3xs);
  margin-bottom: var(--ds-spacing-4xs);
  text-transform: lowercase;
  font-size: var(--label-size);
  font-weight: var(--label-weight);

  @media (--ds-breakpoint-md) {
    margin-bottom: var(--ds-spacing-xs);
  }
}

.timeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  font-variant-numeric: tabular-nums;
}

.separator {
  margin: 0 var(--ds-spacing-fluid-xs);
}

.number,
.separator {
  line-height: 1;
  color: var(--ds-color-layer-transparent-light-2);
  font-size: var(--number-size);
  font-weight: var(--number-weight);
}

.label {
  /* ds-body-xs-regular font-size copied from Skroll */
  font-size: 0.75rem;

  /* ds-body-md-regular font-size copied from Skroll */
  @media (--ds-breakpoint-md) {
    font-size: 1rem;
  }
}

.contrastDark .number,
.contrastDark .separator {
  color: var(--ds-color-layer-transparent-dark-1);
}

.announcer {
  display: none;
}
