.absoluteLayer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: var(--st-dimension-max-width);
  min-width: 320px;
  position: relative;
}

.mediaWrapper {
  position: relative;
  height: 0;
}

.mediaWrapper_standard {
  padding-top: var(--padding-top-small);

  @media (--breakpoint-small-min) {
    padding-top: var(--padding-top-medium);
  }

  @media (--breakpoint-medium-min) {
    padding-top: var(--padding-top-large);
  }
}

.mediaWrapper_16x9 {
  padding-bottom: 100%;

  @media (--breakpoint-small-min) {
    padding-bottom: 56.25%;
  }
}

.logoHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--st-sizing-xs);
}

.secondaryLogo {
  margin-left: var(--st-sizing-xs);
}

.linksHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;

  @media (--breakpoint-small-min) {
    align-items: baseline;
    justify-content: unset;
  }
}

.playVideoButtonWrapper {
  margin: var(--st-sizing-xs) var(--st-sizing-3xs) 0 var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    margin-top: var(--st-sizing-ms);
  }
}

.playButtonWrapper .joinLink,
.callToActionLink {
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm1);
  padding: var(--st-sizing-3xs) var(--st-sizing-ms);
  margin: var(--st-sizing-xs) var(--st-sizing-3xs) 0 var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
    padding: var(--st-sizing-3xs) var(--st-sizing-xs);
    line-height: var(--st-line-height-s0);
    margin-top: var(--st-sizing-ms);
  }
  pointer-events: all;
}

.linksHolder .playVideoButton {
  pointer-events: all;
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm1);
  padding: var(--st-sizing-3xs) var(--st-sizing-ms);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
    padding: var(--st-sizing-3xs) var(--st-sizing-xs);
    line-height: var(--st-line-height-s0);
  }
}

.linksHolder .mediaQueryPlayVideoButton {
  margin: var(--st-sizing-xs) var(--st-sizing-3xs) 0 var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    margin-top: var(--st-sizing-ms);
  }

  @media (--breakpoint-medium-max) {
    display: none;
  }
}

.playVideoIconButton {
  composes: absoluteLayer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.contentWrapper {
  margin: var(--st-sizing-ms) var(--st-sizing-lg);

  @media (--breakpoint-small-min) {
    max-width: 45%;
    margin-left: var(--st-sizing-xxl);
    margin-right: var(--st-sizing-xxl);
  }

  @media (--breakpoint-medium-min) {
    max-width: 35%;
    margin-left: var(--st-sizing-4xl);
    margin-right: var(--st-sizing-4xl);
  }
}

.contentWrapper .titleText {
  margin: 0;
  font-size: var(--st-font-size-s1);
  line-height: var(--st-line-height-s1);

  @media (--breakpoint-small-min) {
    font-size: var(--st-font-size-s2);
    line-height: var(--st-line-height-s2);
  }

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s4);
    line-height: var(--st-line-height-s4);
  }

  @media (--breakpoint-large-min) {
    font-size: var(--st-font-size-s5);
    line-height: var(--st-line-height-s5);
  }
}

.contentWrapper .bodyText {
  display: block;
  font-size: var(--st-font-size-sm1);
  margin-top: var(--st-sizing-3xs);
  line-height: var(--st-line-height-sm1);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s1);
    margin-top: var(--st-sizing-xs);
    line-height: var(--st-line-height-s1);
  }
}

.contentContainer {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  pointer-events: none;
  position: relative;
  padding: 0;
  z-index: 1;

  @media (--breakpoint-small-min) {
    position: absolute;
    background-color: transparent;
    inset: 0;
  }
}

.container_Medium {
  composes: absoluteLayer;
  background-color: transparent;
}

.align_Top {
  align-items: flex-start;
}
.align_Middle {
  align-items: center;
}
.align_Bottom {
  align-items: flex-end;
}

.justify_Center {
  justify-content: center;
}
.justify_Left {
  @media (--breakpoint-small-min) {
    justify-content: flex-start;
  }
}
.justify_Right {
  @media (--breakpoint-small-min) {
    justify-content: flex-end;
  }
}

.text_Left {
  @media (--breakpoint-small-min) {
    text-align: left;
  }
}
.text_Right {
  @media (--breakpoint-small-min) {
    text-align: right;
  }
}
.text_Center {
  text-align: center;
}

.d_MediaLogo {
  display: flex;

  @media (--breakpoint-small-min) {
    display: none;
  }
}
.d_ContentLogo {
  display: none;

  @media (--breakpoint-small-min) {
    display: flex;
  }
}

.theme_Dark {
  color: var(--st-color-black);
}
.theme_Light {
  color: var(--st-color-white);
}

.logo_AboveContent {
  @media (--breakpoint-small-min) {
    position: relative;
    padding: 0 0 var(--st-sizing-xs) 0;
  }
}
.logo_BottomLeft {
  justify-content: flex-start;
}
