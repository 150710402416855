.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerWrapper {
  width: 100%;
  max-width: 50rem;
  padding: var(--ds-spacing-xl) var(--ds-spacing-sm);
}

.titleLight {
  color: var(--ds-color-text-inverse);
}

.titleDark {
  color: var(--ds-color-text-default);
}

.bodyLight {
  color: var(--ds-color-text-inverse);
  padding: var(--ds-spacing-2xs) 0 var(--ds-spacing-2xs) 0;
}

.bodyDark {
  color: var(--ds-color-text-default);
  padding: var(--ds-spacing-2xs) 0 var(--ds-spacing-2xs) 0;
}

.callToActionWrapper {
  padding-top: var(--ds-spacing-3xs);
  display: flex;
  flex-wrap: wrap;
  gap: var(--ds-spacing-2xs);
}

.left {
  justify-content: left;
  text-align: left;
  margin: 0;
  margin-right: auto;
  text-align: left;
}

.right {
  justify-content: right;
  text-align: right;
  margin: 0;
  margin-left: auto;
  text-align: right;
}

.center {
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  text-align: center;
}
