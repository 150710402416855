.cardWrapper {
  /* no appropriate Skrøll token available */
  min-width: 70px;
  max-width: 120px;
}

.cardLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--label-color);
}

.cardLink:hover {
  text-decoration: underline;
  color: var(--label-color);
}

.cardImage {
  width: 100%;
  aspect-ratio: 11 / 9;
  overflow: hidden;

  & img {
    width: 100%;
    user-select: none;
    pointer-events: none;
  }
}

.label {
  padding-top: var(--ds-spacing-fluid-2xs);
  text-align: center;
  word-break: break-word;
}
