.container {
  --navigation-height: 50px;
  --indicator-height: 6px;
  --indicator-margin: 3px;
  --navigation-container-height: calc(
    var(--navigation-height) + var(--indicator-height) + var(--indicator-margin)
  );

  position: relative;
  width: 100%;
  display: flex;
  overflow-x: hidden;
  z-index: 2;
}
.navScrollerContainer {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  overflow-x: visible;
}

.navContainer {
  display: flex;
  width: fit-content;
  position: relative;
  touch-action: none;
  cursor: var(--is-draggable, pointer);
  z-index: 1;
  &:active {
    cursor: var(--is-active-draggable, pointer);
  }
}
.navIndicator {
  height: var(--nav-height);
  border-radius: calc(var(--nav-height) / 2);
  position: absolute;
  top: 0;
  transition: all var(--nav-animation-time) ease-in-out;

  &:after {
    width: calc(var(--ind-height) * 2);
    height: var(--ind-height);
    content: '';
    border-bottom: solid var(--ind-height) white;
    border-left: solid var(--ind-height) transparent;
    border-right: solid var(--ind-height) transparent;
    position: absolute;
    top: var(--ind-top);
    left: 0;
    right: 0;
    margin: auto;
  }
}

.navButtonItem {
  height: 100%;
  padding: 0 var(--st-sizing-md);
  align-self: center;
  user-select: none;
  color: var(--nav-button-color);
  & span {
    border: 1px solid transparent;
    transition: border var(--nav-animation-time) ease-in;
  }
  &:focus {
    & span {
      border-bottom: 1px solid var(--nav-button-color);
    }
  }
}

.navBackground {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: calc(var(--nav-height) / 2);
  width: 100%;
  height: var(--nav-height);
  display: block;
  position: absolute;
  top: 0;
}
