.textSection .title {
  font-size: var(--st-font-size-s1);
  margin: 0 0 var(--st-sizing-ms);

  @media (--breakpoint-small-min) {
    font-size: var(--st-font-size-s2);
  }

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s4);
  }
}

.textSection .description {
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm2);
  margin: 0 0 var(--st-sizing-ms);
}

.links {
  display: inline-flex;
  flex-wrap: wrap;
}

.links .playFullVideoCTA,
.callToAction {
  font-size: var(--st-font-size-sm1);
  margin-right: var(--st-sizing-3xs);
  margin-bottom: var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
  }
}

.videoSection {
  position: relative;
  padding-bottom: 100%;

  @media (--breakpoint-small-min) {
    flex: 1 0 50%;
    padding-bottom: 50%;
    min-width: 50%;
  }
}
.wrapper {
  @media (--breakpoint-small-min) {
    display: flex;
  }
}
.flexDir_Right {
  @media (--breakpoint-small-min) {
    flex-direction: row;
  }
}
.flexDir_Left {
  @media (--breakpoint-small-min) {
    flex-direction: row-reverse;
  }
}
.textSection {
  padding: var(--st-sizing-ms);

  @media (--breakpoint-small-min) {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    flex: 1 0 50%;
    justify-content: center;
    min-width: 50%;
    padding: var(--st-sizing-lg);
  }

  @media (--breakpoint-medium-min) {
    padding: var(--st-sizing-4xl);
  }
}
.theme_Dark {
  color: var(--st-color-black);
}
.theme_Light {
  color: var(--st-color-white);
}
.logos {
  align-items: center;
  display: none;
  bottom: var(--st-sizing-md);
  flex-direction: row;
  position: absolute;
  z-index: 1;

  @media (--breakpoint-medium-min) {
    display: inline-flex;
  }
}
.justify_Left {
  justify-content: flex-start;
}
.justify_Right {
  justify-content: flex-end;
}
