.article-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.article-content-wrapper__article-width-container {
  padding: var(--st-sizing-md);
  width: 100%;
}

.article-content-wrapper__article-width-container > *:last-child {
  margin-bottom: 0;
}

.article-content-wrapper__article-width-container-cta-container {
  margin-top: var(--st-sizing-md);
}

.article-content-wrapper__article-width-container-cta-container--center {
  text-align: center;
}

.article-content-wrapper__article-width-container-cta-container--right {
  text-align: right;
}

.article-content-wrapper__article-width-container__product-leaf-wrapper {
  border: 1px solid var(--st-color-grey-light);
  margin: var(--st-sizing-3xs) auto;
  width: 100%;
}

.article-content-wrapper__article-width-container__product-leaf-wrapper--left--4xl {
  margin-left: var(--st-sizing-4xl);
}

.article-content-wrapper__article-width-container__product-leaf-wrapper--right--4xl {
  margin-right: var(--st-sizing-4xl);
}

.article-content-wrapper__article-width-container__rich-text-container {
  text-align: left;
}

/* 
The below CSS repeats a class name multiple times. This has been done to avoid the Next error regarding pure selectors that occurs on the client when you 
simply nest pure selectors (such as a, h1, p, etc.) under one class. Each pure selector needs to be related to a local class to avoid this.
ArticleContent is created using the rich text field in Contentstack (represented by Markup in our .tsx file), so we also can't add classes directly to the h1, a etc as they will
only exist when inputted into the rich text field. But we still need to ensure the correct styling is applied to these elements.
*/

.article-content-wrapper__article-width-container__rich-text-container a {
  text-decoration: underline;

  &:active,
  &:hover {
    text-decoration: none;
  }
}

.article-content-wrapper__article-width-container__rich-text-container h1 {
  font-size: var(--st-font-size-s3);
  line-height: var(--st-line-height-s3);
  font-weight: var(--st-font-weight-normal);
}

.article-content-wrapper__article-width-container__rich-text-container ul {
  display: block;
  margin: var(--st-sizing-sm) 0;
  padding-left: var(--st-sizing-lg);
}

.article-content-wrapper__article-width-container__rich-text-container ol {
  display: block;
  margin: var(--st-sizing-sm) 0;
  padding-left: var(--st-sizing-lg);
}

.article-content-wrapper__article-width-container__rich-text-container li {
  line-height: var(--st-line-height-sm1);
  margin-bottom: var(--st-sizing-xxs);
  font-size: var(--st-font-size-sm1);
}

.article-content-wrapper__article-width-container__rich-text-container ul li {
  list-style-type: disc;
}

.article-content-wrapper__article-width-container__rich-text-container ol li {
  list-style-type: decimal;
}

.article-content-wrapper__article-width-container__rich-text-container h2 {
  font-weight: var(--st-font-weight-bold);
  font-size: var(--st-font-size-s2);
  line-height: var(--st-line-height-s2);
  margin-top: var(--st-sizing-md);
  margin-bottom: var(--st-sizing-3xs);
}

.article-content-wrapper__article-width-container__rich-text-container h2 b {
  font-weight: var(--st-font-weight-black);
}

.article-content-wrapper__article-width-container__rich-text-container
  h2
  strong {
  font-weight: var(--st-font-weight-black);
}

.article-content-wrapper__article-width-container__rich-text-container p {
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm1);
}

.article-content-wrapper__article-width-container__rich-text-container--center {
  text-align: center;
  list-style-position: inside;
}

.article-content-wrapper__article-width-container__rich-text-container--right {
  text-align: right;
  list-style-position: inside;
}

.article-content-wrapper__article-width-container__rich-text-container--dark-mode {
  color: var(--st-color-white);

  .article-content-wrapper__article-width-container-cta-container a {
    color: inherit;
  }
}

.article-content-wrapper__article-width-container__rich-text-container--dark-mode
  a {
  color: var(--st-color-white);
}

/* 600px and above */
@media (--breakpoint-small-min) {
  .article-content-wrapper__article-width-container {
    padding: var(--st-sizing-lg);
  }

  .article-content-wrapper__article-width-container-cta-container {
    margin-top: var(--st-sizing-lg);
  }

  .article-content-wrapper__article-width-container__product-leaf-wrapper {
    margin-top: 0;
    margin-left: var(--st-sizing-ms);
    margin-right: var(--st-sizing-ms);
    margin-bottom: var(--st-sizing-ms);
  }

  .article-content-wrapper__article-width-container__product-leaf-wrapper--right {
    margin-right: 0;
    float: right;
    width: 16.25rem;
  }

  .article-content-wrapper__article-width-container__product-leaf-wrapper--left {
    margin-left: 0;
    float: left;
    width: 16.25rem;
  }

  .article-content-wrapper__article-width-container__rich-text-container h1 {
    font-size: var(--st-font-size-s4);
    line-height: var(--st-line-height-s4);
  }

  .article-content-wrapper__article-width-container__rich-text-container h2 {
    font-size: var(--st-font-size-s3);
    line-height: var(--st-line-height-s3);
    margin-top: var(--st-sizing-lg);
    margin-bottom: var(--st-sizing-sm);
  }

  .article-content-wrapper__article-width-container__rich-text-container li {
    font-size: var(--st-font-size-s0);
    line-height: var(--st-line-height-s0);
  }

  .article-content-wrapper__article-width-container__rich-text-container p {
    font-size: var(--st-font-size-s0);
    line-height: var(--st-line-height-s0);
  }
}

/* 900px and above */
@media (--breakpoint-medium-min) {
  .article-content-wrapper__article-width-container__product-leaf-wrapper {
    margin-top: 0;
    margin-right: var(--st-sizing-lg);
    margin-bottom: var(--st-sizing-lg);
    margin-left: var(--st-sizing-lg);
  }

  .article-content-wrapper__article-width-container__product-leaf-wrapper--right {
    margin-right: -6.25rem;
  }

  .article-content-wrapper__article-width-container__product-leaf-wrapper--left {
    margin-left: -6.25rem;
  }
}

/* 1200px and above */
@media (--breakpoint-large-min) {
  .article-content-wrapper__article-width-container__product-leaf-wrapper--right,
  .article-content-wrapper__article-width-container__product-leaf-wrapper--left {
    width: 18.75rem;
  }
}
