.mediaWrapper {
  position: relative;
  height: 0;
  padding-bottom: 100%;
  z-index: 1;

  @media (--breakpoint-small-min) {
    padding-bottom: 56.25%;
  }
}

.logoHolder {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: var(--st-sizing-xs);
  pointer-events: none;
  z-index: 1;
}

.secondaryLogo {
  margin-left: var(--st-sizing-xs);
}

.contentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--content-bg-color);
  position: relative;
  text-align: center;
  z-index: 1;

  @media (--breakpoint-small-min) {
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 50%;
  }
}

.content {
  padding: var(--st-sizing-ms) var(--st-sizing-lg);

  @media (--breakpoint-small-min) {
    padding: 0;
  }
}

.content .titleText {
  margin: 0;
  font-size: var(--st-font-size-s1);
  line-height: var(--st-line-height-s1);

  @media (--breakpoint-small-min) {
    font-size: var(--st-font-size-s2);
  }

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s4);
    line-height: var(--st-line-height-s4);
  }

  @media (--breakpoint-large-min) {
    font-size: var(--st-font-size-s5);
    line-height: var(--st-line-height-s5);
  }
}

.content .bodyText {
  display: block;
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm1);
  margin-top: var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s1);
    margin-top: var(--st-sizing-xs);
    line-height: var(--st-line-height-s1);
  }
}

.linksHolder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px calc(var(--st-sizing-3xs) * -1);

  @media (--breakpoint-medium-min) {
    justify-content: var(--pos-to-flex);
  }
}

.playVideoButtonWrapper {
  margin: var(--st-sizing-xs) var(--st-sizing-3xs) 0 var(--st-sizing-3xs);

  @media (--breakpoint-medium-min) {
    margin-top: var(--st-sizing-ms);
  }
}

/* 
The ButtonWithIcon and LinkWithChevron component styles will overwrite the font size, margin and padding we intend
so we need to be specific with our selectors in the below.
*/
.playVideoButtonWrapper .playVideoButton {
  font-size: var(--st-font-size-sm1);
  padding: var(--st-sizing-3xs) var(--st-sizing-ms);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
    padding: var(--st-sizing-3xs) var(--st-sizing-xs);
  }
}

.linksHolder .callToActionLink {
  margin: var(--st-sizing-xs) var(--st-sizing-3xs) 0 var(--st-sizing-3xs);
  font-size: var(--st-font-size-sm1);
  padding: var(--st-sizing-3xs) var(--st-sizing-ms);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
    padding: var(--st-sizing-3xs) var(--st-sizing-xs);
    margin-top: var(--st-sizing-ms);
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: var(--st-dimension-max-width);
  min-width: 320px;
  position: relative;
}

.content_Left {
  @media (--breakpoint-small-min) {
    left: var(--st-sizing-xxl);
    transform: translateY(-50%);
  }

  @media (--breakpoint-medium-min) {
    left: var(--st-sizing-4xl);
  }
}
.content_Right {
  @media (--breakpoint-small-min) {
    right: var(--st-sizing-xxl);
    transform: translateY(-50%);
  }

  @media (--breakpoint-medium-min) {
    right: var(--st-sizing-4xl);
  }
}

.content_Center {
  @media (--breakpoint-small-min) {
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

.theme_Dark {
  color: var(--st-color-white);
}
.theme_Light {
  color: var(--st-color-black);
}

.flex_Left {
  @media (--breakpoint-small-min) {
    justify-content: flex-start;
  }
}
.flex_Center {
  justify-content: center;
}
.flex_Right {
  @media (--breakpoint-small-min) {
    justify-content: flex-end;
  }
}

.logoPosition_BottomLeft {
  justify-content: flex-start;
}
.logoPosition_BottomRight {
  justify-content: flex-end;
}

.text_Left {
  @media (--breakpoint-small-min) {
    text-align: left;
  }
}
.text_Center {
  text-align: center;
}
.text_Right {
  @media (--breakpoint-small-min) {
    text-align: right;
  }
}
