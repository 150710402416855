.add-to-bag-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  & > button {
    min-width: initial;
    overflow: hidden;

    & > span {
      height: 100%;
      line-height: 100%;
    }
  }
}
