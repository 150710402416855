.wrapper {
  text-align: right;
  flex-basis: 25%;
  position: relative;
  display: flex;
  gap: var(--ds-spacing-3xs);
  align-items: center;
  justify-content: right;
}

.slider {
  height: var(--ds-size-md);
  appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;
  max-width: 80px;
  visibility: hidden;

  @media (--ds-breakpoint-sm) {
    visibility: visible;
  }

  --track-bg: var(--ds-color-layer-inverse);
  --progress-bg: var(--ds-color-layer-neutral-primary);
  --track-computed-bg: linear-gradient(
    to right,
    var(--progress-bg) var(--volume),
    var(--track-bg) 0
  );
}
.slider.light {
  --track-bg: var(--ds-color-layer-inverse);
  --progress-bg: var(--ds-color-layer-default);
}
.slider:focus {
  outline: none;
}
.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: var(--track-computed-bg);
  border-radius: 29px;
}
.slider::-webkit-slider-thumb {
  height: var(--ds-size-3xs);
  width: var(--ds-size-3xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -2px;
}
.slider:focus::-webkit-slider-runnable-track {
  background: var(--track-computed-bg);
}
.slider::-moz-range-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: var(--track-computed-bg);
  border-radius: 29px;
}
.slider::-moz-range-thumb {
  height: var(--ds-size-3xs);
  width: var(--ds-size-3xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
.slider::-ms-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.slider::-ms-fill-lower {
  background: var(--track-computed-bg);
  border-radius: 58px;
}
.slider::-ms-fill-upper {
  background: var(--track-computed-bg);
  border-radius: 58px;
}
.slider::-ms-thumb {
  margin-top: 1px;
  height: var(--ds-size-3xs);
  width: var(--ds-size-3xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
.slider:focus::-ms-fill-lower {
  background: var(--track-computed-bg);
}
.slider:focus::-ms-fill-upper {
  background: var(--track-computed-bg);
}
