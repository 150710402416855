.productLeafWrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--leaf-background-color);
  border: 1px solid var(--leaf-frame-color);

  /* max width property prevents product leaves from scaling too wide if there are fewer than 5 leaves i.e. if most products are sold out */
  max-width: 360px;

  @media (--ds-breakpoint-sm) {
    max-width: 352px;
  }

  @media (--ds-breakpoint-md) {
    max-width: 330px;
  }

  @media (--ds-breakpoint-lg) {
    max-width: 292px;
  }
}
