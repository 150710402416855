.in-page-nav {
  width: 100%;
  /* Since the images being used for the background are retrieved as 90px height, to avoid stretching, better keep this to 90px
   * Not using global tokens since they could change at any point, and that's not considered a breaking change
   * In the future we could (or should) change the config so it adapts better to skroll guidelines */
  height: 90px;
  padding: var(--ds-spacing-2xs);

  display: flex;
  align-items: center;
  justify-content: center;

  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--_bg-color);
  background-image: var(--_bg-small-image-url);

  @media (--ds-breakpoint-sm) {
    background-image: var(--_bg-medium-image-url);
  }
  @media (--ds-breakpoint-md) {
    background-image: var(--_bg-large-image-url);
  }

  & a {
    /* composes does not work with "complex" rules */
    margin-right: var(--ds-spacing-3xs);
    max-width: max-content;

    @media (--ds-breakpoint-sm) {
      margin-right: var(--ds-spacing-sm);
    }
    @media (--ds-breakpoint-md) {
      margin-right: var(--ds-spacing-xl);
    }

    &:last-of-type {
      margin-right: 0;
    }

    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.spacing {
  margin-right: var(--ds-spacing-3xs);

  @media (--ds-breakpoint-sm) {
    margin-right: var(--ds-spacing-sm);
  }
  @media (--ds-breakpoint-md) {
    margin-right: var(--ds-spacing-xl);
  }
}

.logo {
  height: auto;
  width: auto;

  max-height: var(--ds-size-2xl);
  max-width: 10rem;
  @media (--ds-breakpoint-sm) {
    max-width: 12rem;
  }
  composes: spacing;
}

.title {
  composes: ds-heading-xl from global;
  text-align: center;

  &:not(:only-child) {
    /*Repeating the spacing rule here because we cannot compose it in a non-local rule */
    margin-right: var(--ds-spacing-3xs);

    @media (--ds-breakpoint-sm) {
      margin-right: var(--ds-spacing-xl);
    }
    @media (--ds-breakpoint-md) {
      margin-right: var(--ds-spacing-xl);
    }
  }
}

.light {
  color: var(--ds-color-text-inverse);
}

.dark {
  color: var(--ds-color-text-default);
}
