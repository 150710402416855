.carouselWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1600px;
  background-color: var(--carousel-background-color);
  color: var(--text-color);
  padding: var(--ds-spacing-md) var(--ds-spacing-xs);

  @media (--ds-breakpoint-sm) {
    padding: var(--ds-spacing-lg);
  }
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--ds-spacing-xs);
  padding-right: var(--ds-spacing-5xs);
  padding-top: var(--ds-spacing-5xs);
}

.carouselVariant {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--ds-spacing-xs);

  @media (--ds-breakpoint-sm) {
    flex-direction: row;
  }
}

.title {
  margin: 0;
  padding: 0;
}

.navigationArrows {
  display: none;

  @media (--ds-breakpoint-sm) {
    display: flex;
    gap: var(--ds-spacing-3xs);
  }
}

.carouselViewport {
  overflow-x: auto;
  padding-top: var(--ds-spacing-5xs);
  padding-bottom: var(--ds-spacing-md);
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar-track {
    @media (--ds-breakpoint-lg) {
      margin: 0 312px;
    }
  }
}

.items {
  /* Read this as [carousel viewport width] - [all the gaps] - [the padding on the sides] */
  --carousel-viewport-xs: calc(
    100vw - (var(--visible-items-xs) - 1) * var(--row-gap-xs) - 2 *
      var(--ds-spacing-xs)
  );
  --carousel-viewport-sm: calc(
    100vw - (var(--visible-items-sm) - 1) * var(--row-gap-sm) - 2 *
      var(--ds-spacing-lg)
  );
  --carousel-viewport-md: calc(
    100vw - (var(--visible-items-md) - 1) * var(--row-gap-md) - 2 *
      var(--ds-spacing-lg)
  );
  --carousel-viewport-lg: calc(
    100vw - (var(--visible-items-lg) - 1) * var(--row-gap-lg) - 2 *
      var(--ds-spacing-lg)
  );

  --carousel-viewport-xl: calc(
    1600px - (var(--visible-items-xl) - 1) * var(--row-gap-xl) - 2 *
      var(--ds-spacing-lg)
  );

  display: flex;
  justify-content: space-between;
  width: fit-content;
  gap: var(--row-gap-xs);

  @media (--ds-breakpoint-sm) {
    gap: var(--row-gap-sm);
  }

  @media (--ds-breakpoint-md) {
    gap: var(--row-gap-md);
  }

  @media (--ds-breakpoint-lg) {
    gap: var(--row-gap-lg);
  }

  @media (--ds-breakpoint-xl) {
    gap: var(--row-gap-xl);
  }

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  & > * {
    scroll-snap-align: start;
    width: calc(var(--carousel-viewport-xs) / var(--visible-items-xs));

    @media (--ds-breakpoint-sm) {
      width: calc(var(--carousel-viewport-sm) / var(--visible-items-sm));
    }

    @media (--ds-breakpoint-md) {
      width: calc(var(--carousel-viewport-md) / var(--visible-items-md));
    }

    @media (--ds-breakpoint-lg) {
      width: calc(var(--carousel-viewport-lg) / var(--visible-items-lg));
    }

    @media (--ds-breakpoint-xl) {
      width: calc(var(--carousel-viewport-xl) / var(--visible-items-xl));
    }
  }
}
