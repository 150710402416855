.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (--breakpoint-small-min) {
    flex-direction: var(--image-alignment);
  }
}

.image {
  background-image: var(--image-url);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100%;
  flex: 1;

  @media (--breakpoint-small-min) {
    max-width: 50%;
    padding-top: unset;
  }
}

.containerInner {
  background-color: var(--background-color);
  position: relative;
  flex: 1;
  padding: var(--st-sizing-ms);

  @media (--breakpoint-medium-min) {
    max-width: 50%;
    padding: var(--st-sizing-4xl) var(--st-sizing-ms);
  }

  @media (--breakpoint-large-min) {
    padding: var(--st-sizing-4xl);
  }
}

.title {
  font-size: var(--st-font-size-s1);
  font-weight: var(--st-font-weight-normal);
  color: var(--font-color);
  margin: 0;

  @media (--breakpoint-large-min) {
    font-size: var(--st-font-size-s4);
  }
}

.answerWrapper {
  width: 100%;
  margin-top: var(--st-sizing-xs);
  display: flex;
  align-items: center;
}

.answer {
  position: relative;
  outline: 2px solid var(--border-color);
  border-radius: var(--st-sizing-xxl);
  cursor: var(--pointer-style);
  flex: 1;
  overflow: hidden;
  width: 100%;
  padding: var(--st-sizing-3xs) var(--st-sizing-xs);

  @media (--breakpoint-medium-min) {
    padding: var(--st-sizing-3xs) var(--st-sizing-lg);
  }

  @media (--breakpoint-large-min) {
    padding: var(--st-sizing-xxs) var(--st-sizing-lg);
  }

  &:hover {
    background-color: var(--answer-fill-color);
  }
}

.answerFill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--answer-fill-color);
  border-top-right-radius: var(--st-sizing-xxl);
  border-bottom-right-radius: var(--st-sizing-xxl);
}

.answerText {
  color: var(--font-color);
  position: relative;
  z-index: 1;
  font-size: var(--st-font-size-sm2);
  text-align: left;
  margin: unset;

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
    padding: unset;
  }
}

.votePercentage {
  opacity: 0;
  margin: 0 var(--st-sizing-3xs);
  flex: 0 1 auto;
  width: var(--st-sizing-xl);
  color: var(--font-color);
}

.totalVotesText {
  opacity: 0;
  display: block;
  height: var(--st-sizing-xs);
  margin-top: var(--st-sizing-xxs);
  text-align: center;
  color: var(--font-color);
  position: relative;
  z-index: 1;
  font-size: var(--st-font-size-sm2);

  @media (--breakpoint-medium-min) {
    font-size: var(--st-font-size-s0);
  }
}

.submissionTextWrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--st-sizing-lg);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.submissionTextWrapper {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: var(--st-sizing-lg);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.submissionConfirmationTitle {
  color: var(--font-color);
}

.submissionContent {
  padding: 0 var(--st-sizing-xl);
  font-size: var(--st-font-size-s0);
  color: var(--font-color);

  @media (--breakpoint-large-min) {
    font-size: var(--st-font-size-s2);
  }
}
