.ul {
  padding: var(--ds-spacing-md) 0;
  gap: 0 var(--ds-spacing-sm);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (--ds-breakpoint-md) {
    flex-wrap: nowrap;
    padding: var(--ds-spacing-md) var(--ds-spacing-sm);
  }
}

.li {
  padding-bottom: var(--ds-spacing-2xs);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 4.625rem; /* 74px, as there is not token available, value is hardcoded */
  width: 100%;

  @media (--ds-breakpoint-md) {
    max-width: 7.5rem; /* 120px, as there is not token available, value is hardcoded */
  }
}

.li :hover {
  text-decoration: underline;
}

.label {
  padding-top: var(--ds-spacing-2xs);
  color: var(--ds-color-text-default);
  text-align: center;
}

.labelLight {
  color: var(--ds-color-text-inverse);
}

.labelDark {
  color: var(--ds-color-text-default);
}
