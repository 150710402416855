.blocks-wrapper {
  display: flex;
  flex-direction: column;
}

.blocks-wrapper > .large-block-container:nth-child(2) {
  margin-right: 0;
}

.large-block-container {
  position: relative;
  display: flex;
  margin-bottom: var(--st-sizing-ms);
}

.large-block-inner-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column-reverse;
}

.large-block-content {
  text-align: center;
  padding: var(--st-sizing-ms);
  width: 100%;
  color: var(--st-color-white);
  background-color: var(--content-bg-color);
}

.large-block-content--dark {
  color: var(--st-color-black);
}

.styled-secondary-logo {
  margin-left: var(--st-sizing-sm);
}

.large-block-content .styled-content-header,
.small-block-content .styled-content-header {
  margin: 0 0 var(--st-sizing-xs) 0;
  font-size: var(--st-font-size-s1);
  line-height: var(--st-line-height-s1);
  font-weight: var(--st-font-weight-semi-bold);
}

.large-block-content .description,
.small-block-content .description {
  font-size: var(--st-font-size-sm1);
  line-height: var(--st-line-height-sm2);
  margin: 0 0 var(--st-sizing-ms) 0;
}

.small-block-wrapper {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.small-block-container {
  margin-bottom: var(--st-sizing-ms);
  background-color: var(--content-bg-color);
}

.logo-holder .styled-logo {
  height: auto;
  width: auto;
  min-width: 0;
  max-height: 3.125rem;
  max-width: 9.375rem;
}

.small-block-container:last-child {
  margin-bottom: 0;
}

.small-block-content {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: var(--st-sizing-ms);
  color: var(--st-color-white);
}

.small-block-content--dark {
  color: var(--st-color-black);
}

.small-block-inner-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  overflow: hidden;
}

.small-block-content-wrapper {
  position: relative;
}

.image-placeholder {
  position: relative;
  width: 100%;
  height: 0;
  /* The fixed percentage is needed here to ensure all the images appear on mobile */
  padding-top: 69.33%;
}

.call-to-action-text {
  line-height: var(--st-line-height-s1);
  margin: 0;
  white-space: nowrap;
  margin-right: var(--st-sizing-xxs);
}

.call-to-action-text:hover {
  text-decoration: underline;
}

.styled-link {
  width: 100%;
  height: 100%;
  display: flex;
}

.styled-link:hover {
  text-decoration: none;
}

.no-link-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.content-block-border .module-title {
  font-size: var(--st-font-size-s2);
  line-height: var(--st-line-height-s2);
  font-weight: var(--st-font-weight-normal);
  margin: 0;
  margin-bottom: var(--st-sizing-xs);
}

.content-block-border {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.call-to-action-flex-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logo-holder {
  position: absolute;
  pointer-events: none;
  bottom: var(--st-sizing-sm);
}

.logo-holder--left {
  left: 3%;
}

.logo-holder--right {
  right: 3%;
}

/* styles applied when width exceeds 600px */
@media (--breakpoint-small-min) {
  .blocks-wrapper {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .large-block-container {
    flex: 1 0 auto;
    margin-right: var(--st-sizing-ms);
    margin-bottom: 0;
  }

  .large-block-inner-container {
    flex-direction: column;
  }

  .large-block-content {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background-color: transparent;
    padding: var(--st-sizing-md) var(--st-sizing-ms);
  }

  .large-block-content .styled-content-header,
  .small-block-content .styled-content-header {
    font-size: var(--st-font-size-s0);
    line-height: var(--st-line-height-s0);
  }

  .large-block-content .description,
  .small-block-content .description {
    display: none;
  }

  .large-block-content--top-0 {
    top: 0;
  }

  .large-block-content--bottom-0 {
    bottom: 0;
  }

  .small-block-content {
    position: absolute;
  }

  .small-block-content > .call-to-action-flex-container {
    justify-content: flex-start;
  }

  .small-block-content-wrapper {
    width: 50%;
  }

  .small-block-inner-container {
    padding-bottom: 0;
    flex-direction: row;
    text-align: left;
    overflow: hidden;
  }

  .small-block-inner-container--row-reverse {
    flex-direction: row-reverse;
  }

  .small-block-inner-container > .image-placeholder {
    padding-top: 0;
    height: 100%;
    width: 50%;
  }

  .content-block-border .module-title {
    margin-top: var(--st-sizing-ms);
    font-size: var(--st-font-size-s3);
    line-height: var(--st-line-height-s3);
  }

  .small-block-wrapper {
    max-width: calc(50% - var(--st-sizing-ms) / 2);
  }

  .small-block-container {
    margin-bottom: var(--st-sizing-ms);
    margin-bottom: 0;
    height: calc(50% - var(--st-sizing-ms) / 2);
  }

  .small-block-container:first-child {
    margin-bottom: var(--st-sizing-ms);
  }

  .call-to-action-text {
    font-size: var(--st-sizing-sm1);
  }
}

/* styles applied when width exceeds 900px */
@media (--breakpoint-medium-min) {
  .large-block-content {
    padding: var(--st-sizing-xl) var(--st-sizing-ms);
    background-color: transparent;
  }

  .large-block-content .styled-content-header,
  .small-block-content .styled-content-header {
    font-size: var(--st-font-size-s1);
    line-height: var(--st-line-height-s1);
  }

  .large-block-content .description,
  .small-block-content .description {
    display: block;
  }

  .content-block-border .module-title {
    font-size: var(--st-font-size-s2);
    line-height: var(--st-line-height-s2);
  }
}

/* styles applied when width exceeds 1200px */
@media (--breakpoint-large-min) {
  .content-block-border .module-title {
    font-size: var(--st-font-size-s4);
    line-height: var(--st-line-height-s4);
  }

  .small-block-content {
    padding-left: var(--st-sizing-lg);
    padding-right: var(--st-sizing-lg);
  }

  .large-block-content .styled-content-header,
  .small-block-content .styled-content-header {
    margin-bottom: var(--st-sizing-ms);
    font-size: var(--st-font-size-s3);
    line-height: var(--st-line-height-s3);
  }

  .large-block-content .description,
  .small-block-content .description {
    font-size: var(--st-font-size-sm1);
  }
}
