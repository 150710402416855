.wrapper {
  background: var(--background-color);
  color: var(--ds-color-text-inverse);
  padding: var(--ds-size-2xs);

  @media (--ds-breakpoint-sm) {
    padding: var(--ds-size-2xs) 0;
  }
}

.wrapper.contrastDark {
  color: var(--ds-color-text-default);
}

.countdown {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  height: 100%;
  margin: 0 auto;
  z-index: 2; /* non-Skroll z-index because navbar is set to z-index 3 and not --z-index-nav */
  /* heading-4xl font-size copied from Skroll */
  --number-size: clamp(2.5625rem, 4.44898vw + 1.51977rem, 5.96875rem);
  --number-weight: 700;
  --label-size: 1rem;
  --label-weight: 400;

  @media (--ds-breakpoint-lg) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto 1fr auto;
  }
}

.countdown.daysFormat {
  /* heading-5xl class copied from Skroll */
  --number-size: clamp(3.25rem, 7.91837vw + 1.39413rem, 9.3125rem);
  /* heading-lg class copied from Skroll */
  --label-size: clamp(1.28125rem, 0.367347vw + 1.19515rem, 1.5625rem);
  --label-weight: 700;
  place-items: center;

  @media (--ds-breakpoint-sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.countdown.daysFormat.hasLink {
  @media (--ds-breakpoint-sm) {
    grid-template-columns: 1fr;
  }

  @media (--ds-breakpoint-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.countdownTitle,
.countdownText {
  display: block;
}

.countdownText {
  text-align: center;

  @media (--ds-breakpoint-lg) {
    padding-left: var(--ds-spacing-lg);
    text-align: right;
    place-self: center;
    /* body-lg-medium class copied from Skroll */
    letter-spacing: 0;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5;
  }
}

.countdownTitle {
  color: var(--color);
  padding-bottom: var(--ds-spacing-3xs);
  margin: 0;
}

.countdownTitle.daysFormat,
.countdownText.daysFormat {
  @media (--ds-breakpoint-sm) {
    text-align: right;
    padding-left: var(--ds-spacing-xs);
    margin-right: var(--ds-size-xs);
  }
}

.countdownText.daysFormat.hasLink {
  text-align: center;

  @media (--ds-breakpoint-md) {
    text-align: right;
  }
}

.textContainer {
  text-align: center;

  @media (--ds-breakpoint-lg) {
    grid-column: 2/3;
  }
}

.textContainer.daysFormat {
  grid-column: unset;

  @media (--ds-breakpoint-sm) {
    text-align: right;
  }
}

.textContainer.daysFormat.hasLink {
  @media (--ds-breakpoint-sm) {
    text-align: center;
  }
  @media (--ds-breakpoint-md) {
    text-align: right;
  }
}

.countdownTextWrapper {
  justify-self: center;

  @media (--ds-breakpoint-lg) {
    place-self: center end;
    margin-right: var(--ds-spacing-4xl);
    grid-column: 1/2;
    display: grid;
    grid-template-rows: 70% 30%;
    height: 100%;
  }
}

.countdownNumbers {
  justify-self: center;
  grid-column: unset;

  @media (--ds-breakpoint-lg) {
    grid-column: 2/3;
    display: grid;
    grid-template-rows: 70% 30%;
  }
}

.countdownNumbers.daysFormat {
  display: block;
}

.countdownLinkWrapper {
  justify-self: center;
  margin: var(--ds-size-xs) 0;
  display: grid;
  grid-template-rows: 70% 30%;
  height: 100%;
  align-items: center;

  @media (--ds-breakpoint-lg) {
    place-self: center start;
    margin: 0 0 0 var(--ds-spacing-4xl);
    padding-right: var(--ds-spacing-lg);
  }
}

.countdownLinkWrapper.daysFormat {
  grid-template-rows: unset;
}
