.box {
  position: relative;
  display: flex;
  flex: 1;
  height: var(--box-height);
  overflow: hidden;
}

.cardBackground {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--st-color-black);
  background-size: auto 100%;
  background-position: left;
  transform: translateZ(0);
  background-repeat: no-repeat;
}

.title {
  position: absolute;
  left: 0;
  width: auto;
  height: auto;
  transform-origin: top left;
  writing-mode: vertical-rl;
  text-orientation: sideways;
  top: var(--st-sizing-xs);
  font-size: var(--st-font-size-s4);
  font-weight: var(--st-font-weight-bold);
}

.theme_Dark {
  color: var(--st-color-white);
}

.theme_Light {
  color: var(--st-color-black);
}

.cardTile {
  cursor: pointer;
  width: 100%;
  margin-bottom: var(--st-sizing-4xs);
}

.cardView {
  width: 100%;
  height: 100% !important;
  & .cardBackground {
    width: 100%;
    background-position: unset;
    background-size: contain;
  }

  & .box {
    width: 100%;
    height: 100%;
    padding-bottom: calc(80% * 9 / 17);
    & .title {
      writing-mode: unset;
      font-size: var(--st-font-size-s2);
      right: 0;
      bottom: 0;
    }
  }
}

.contentText {
  position: absolute;
  bottom: 0;
  padding: var(--st-sizing-xs);
  & .baseText {
    display: block;
    font-size: var(--st-font-size-sm1);
    line-height: var(--st-line-height-sm2);
  }
}

.textAlign_Left {
  text-align: left;
}

.textAlign_Right {
  text-align: right;
}

.textAlign_Center {
  text-align: center;
}

.linkWrapper {
  position: relative;
  display: flex;
  padding: 0 var(--item-padding);
  will-change: width;
  &:hover {
    cursor: pointer;
  }
}

.sectionMotion {
  overflow: hidden;
  position: relative;
  & .linkWrapper {
    padding: 0;
    margin-bottom: 0;

    & .box {
      width: 100%;
      height: 100%;
    }
  }
  & .title {
    font-size: var(--st-font-size-s2);
  }
  & .cardBackground {
    width: 100%;
    background-position: unset;
    background-size: cover;
  }
}

.backgroundImage {
  width: 100%;
  height: auto;
}

.callToAction {
  margin-top: var(--st-sizing-xs);
}

.sectionWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  @media (--breakpoint-large-max) {
    & .box {
      min-height: var(--item-height);
    }
    & .cardBackground {
      min-height: var(--item-height);
    }
  }
  @media (--breakpoint-medium-max) {
    & .box {
      min-height: var(--item-height);
    }
    & .cardBackground {
      min-height: var(--item-height);
    }
  }
}

.staticWrapper {
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  padding: var(--st-sizing-3xs);

  & .cardBackground {
    opacity: 0.75;
  }
  & .title {
    top: var(--st-sizing-xs);
    left: var(--st-sizing-xxs);
  }
}

.staticWrapperTablet {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: hidden;
  justify-content: center;
  padding: var(--st-sizing-xs);

  & .wrapper {
    width: 100%;
  }

  @media (prefers-reduced-motion) {
    & .wrapper {
      padding: 0;
      margin: 0;
    }
    & .box {
      width: var(--static-item-width);
    }
    & .cardBackground {
      width: var(--static-item-width);
    }
    & .contentText {
      width: 100%;
      max-width: var(--static-item-width);
    }
  }

  & .sectionWrapper {
    justify-content: center;
  }
  & .linkWrapper {
    padding: 0 var(--item-padding);
    width: 33.3333%;
    margin-bottom: calc(var(--item-padding) * 2 * 4);
  }
  & .box {
    height: auto;
    min-height: var(--item-height);
  }
  & .title {
    top: var(--st-sizing-ms);
    left: var(--st-sizing-ms);
    width: var(--st-sizing-lg);
  }
  @media (--breakpoint-small-max) {
    & .linkWrapper {
      margin-bottom: calc(var(--item-padding) * 4);
      &:nth-child(3n + 2) {
        transform: translateY(calc(var(--item-padding) * 2));
      }
    }
    & .title {
      top: var(--st-sizing-xs);
      left: var(--st-sizing-xs);
      width: var(--st-sizing-xs);
    }
  }
}

.wrapper {
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  padding: var(--st-sizing-md);
  @media (--breakpoint-medium-max) {
    padding: 0 var(--st-sizing-md);
  }
}
