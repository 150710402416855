.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  &.isFullWidth {
    aspect-ratio: 16 / 8;
  }

  @media (min-width: 600px) {
    flex-direction: row;

    &.contentPositionRight {
      flex-direction: row-reverse;
    }

    & > * {
      flex-basis: 50%;
    }
  }
}

.sliderContainer {
  position: relative;
  width: 100%;
  padding-top: 100%;

  @media (min-width: 600px) {
    width: 50%;
    padding-top: 50%;
  }

  &.isFullWidth {
    position: static;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: var(--background-color);
  padding: var(--ds-spacing-lg);

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (--ds-breakpoint-md) {
    padding: 0 var(--ds-spacing-6xl);
  }
}

.titleTextMobile,
.titleTextDesktop {
  color: var(--text-color);
  margin: 0;
}

.titleTextMobile {
  display: block;

  @media (--ds-breakpoint-md) {
    display: none;
  }
}
.titleTextDesktop {
  display: none;

  @media (--ds-breakpoint-md) {
    display: block;
  }
}

.bodyText {
  display: block;
  color: var(--text-color);
  margin: var(--ds-spacing-3xs) 0;

  @media (--ds-breakpoint-md) {
    margin: var(--ds-spacing-xs) 0;
  }
}

.ctaContainer {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ds-spacing-2xs);
}

.cta {
  margin-top: var(--ds-spacing-3xs);
  margin-bottom: var(--ds-spacing-3xs);
}
