.controlsWrapper {
  flex-shrink: 0;
  margin-top: var(--ds-spacing-3xs);
}

.iconButton {
  margin: 0 var(--ds-spacing-xs);
}

.iconButton.playButton {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: var(--ds-spacing-2xs);
}

.progressBar {
  width: 100%;
  border-radius: 1000rem;
  background: var(--ds-color-layer-inverse);
  padding: var(--ds-size-3xs) var(--ds-size-xs);
  display: flex;
  justify-content: center;

  @media (--ds-breakpoint-sm) {
    margin-bottom: var(--ds-spacing-xs);
  }
}

/* custom input[type=range] */
.track {
  height: var(--ds-size-md);
  appearance: none;
  margin: 0;
  width: 100%;
  background: transparent;

  --track-bg: var(--ds-color-layer-neutral-primary);
  --progress-bg: var(--ds-color-core-blue-900);
  --buffer-bg: var(--ds-color-icon-informative);
  --track-computed-bg: linear-gradient(
      to right,
      var(--progress-bg) var(--track-progress),
      transparent 0
    ),
    linear-gradient(
      to right,
      var(--buffer-bg) var(--buffer-progress),
      var(--track-bg) 0
    );
}
.track:focus {
  outline: none;
}
.track::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: var(--track-computed-bg);
  border-radius: 29px;
}
.track::-webkit-slider-thumb {
  height: var(--ds-size-2xs);
  width: var(--ds-size-2xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
}
.track:focus::-webkit-slider-runnable-track {
  background: var(--track-computed-bg);
}
.track::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: var(--track-computed-bg);
  border-radius: 29px;
}
.track::-moz-range-thumb {
  height: var(--ds-size-2xs);
  width: var(--ds-size-2xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
.track::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.track::-ms-fill-lower {
  background: var(--track-computed-bg);
  border-radius: 58px;
}
.track::-ms-fill-upper {
  background: var(--track-computed-bg);
  border-radius: 58px;
}
.track::-ms-thumb {
  margin-top: 1px;
  height: var(--ds-size-2xs);
  width: var(--ds-size-2xs);
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
}
.track:focus::-ms-fill-lower {
  background: var(--track-computed-bg);
}
.track:focus::-ms-fill-upper {
  background: var(--track-computed-bg);
}

.trackControls {
  width: 100%;
  display: grid;
  grid-template-areas:
    'mobileTime mobileTime mobileTime'
    'desktopTime centerControls volumeControls';
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 50px 1fr;

  @media (--ds-breakpoint-sm) {
    grid-template-rows: 0px 1fr;
  }
}

.centerControls {
  display: flex;
  justify-content: center;
  grid-area: centerControls;
}

.timeRemaining {
  grid-area: mobileTime;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (--ds-breakpoint-md) {
    grid-area: desktopTime;
    justify-content: flex-start;

    /* when timestamps are condensed, add slash between times */
    .time:first-child:after {
      content: '/';
    }
  }
}

.timeFiller {
  grid-area: desktopTime;

  @media (--ds-breakpoint-md) {
    grid-area: mobileTime;
  }
}

.volumeControl {
  text-align: right;
  flex-basis: 25%;
  position: relative;
}

.previousNextIcon {
  opacity: 1;
}

.volumeSlider {
  overflow: hidden;
  height: 100%;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  z-index: var(--high);
  background-color: var(--white);
  border-radius: var(--4xs);
}

.volumeTrack {
  width: var(--xxl);
  height: var(--4xs);
  padding-right: var(--lg);
  background-color: var(--black);
  margin-left: var(--xs);
}

.mobileTime {
  display: flex;
  justify-content: space-between;

  @media (--ds-breakpoint-md) {
    display: hidden;
  }
}

.time {
  font-variant-numeric: tabular-nums;
  color: var(--text-colour);
  margin: 0;
  height: fit-content;
}

.iconDisabled {
  opacity: 0.2;
  pointer-events: none;
}
