.text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow-wrap: break-word;

  & * {
    /* If we don't reset the margin/padding of elements, browsers can't calculate the lines correctly */
    margin: 0;
    padding: 0;
    /* Fix for an issue on Safari: https://stackoverflow.com/a/71237382/317666 */
    display: inline-block;
  }
}

.readMoreButton {
  display: block;
  padding: 0;
  border-collapse: collapse;
  background-color: transparent;
  color: var(--ds-color-action-primary-enabled);

  &:hover {
    text-decoration: underline;
  }
}
