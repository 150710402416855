.wrapper {
  max-width: var(--st-dimension-max-width);
  margin: 0 auto;
  width: 100%;
  background-color: var(
    --article-image-wrapper-background,
    var(--ds-color-layer-default)
  );
}

.imageWrapper {
  width: 100%;
  margin: 0 auto;
  max-width: var(--image-wrapper-width);
}

.caption {
  padding: var(--st-sizing-3xs);
  font-size: var(--st-font-size-sm2);
  line-height: var(--st-line-height-sm2);

  @media (--breakpoint-small-min) {
    padding: var(--st-sizing-xs);
    font-size: var(--st-font-size-sm1);
    line-height: var(--st-line-height-sm1);
  }

  color: var(--ds-color-text-default);
  background-color: var(--st-color-grey-light);

  &.darkMode {
    color: var(--ds-color-text-inverse);
    background-color: var(--st-color-grey-dark);
  }
}
