.cardWrapper {
  aspect-ratio: 5 / 7;
  /* no appropriate Skrøll token available */
  min-width: 82px;
  max-width: 202px;
  opacity: 1;
  overflow: hidden;
}

.cardLink {
  display: grid;
}

.cardLink:hover {
  text-decoration: none;
}

.cardImage {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  width: 100%;

  & img {
    width: 100%;
    height: 100%;
    user-select: none;
    pointer-events: none;
  }
}

.labelMobile,
.labelDesktop {
  color: var(--label-color);
  grid-row: 1 / 1;
  grid-column: 1 / 1;
  text-align: var(--label-position-horizontal);
  justify-self: var(--label-position-horizontal);
  align-self: var(--label-position-vertical);
  padding: var(--ds-spacing-fluid-2xs) var(--ds-spacing-3xs);
  hyphens: auto;
}

.labelMobile {
  display: block;

  @media (--ds-breakpoint-lg) {
    display: none;
  }
}

.labelDesktop {
  display: none;

  @media (--ds-breakpoint-lg) {
    display: block;
  }
}
