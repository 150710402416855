.wrapper {
  width: 100%;
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);
  /* on smaller screens, the carousel should be flush to the right hand edge of the screen */
  padding: var(--ds-spacing-fluid-sm) 0 var(--ds-spacing-fluid-sm)
    var(--ds-spacing-fluid-sm);

  @media (--ds-breakpoint-sm) {
    padding: var(--ds-spacing-sm) var(--ds-spacing-lg);
  }

  &.stack {
    padding: var(--ds-spacing-fluid-sm) var(--ds-spacing-fluid-2xs);

    @media (--ds-breakpoint-xs) {
      padding: var(--ds-spacing-fluid-sm);
    }

    @media (--ds-breakpoint-xl) {
      padding: var(--ds-spacing-fluid-sm) var(--ds-spacing-lg);
    }

    @media (--ds-breakpoint-xl) {
      padding: var(--ds-spacing-sm) var(--ds-spacing-lg);
    }
  }

  &.left {
    @media (--ds-breakpoint-lg) {
      display: grid;
      grid-template-columns: 14% 86%;

      &:has([data-test='carousel']) [data-test='carousel'] div ol li {
        width: 100%;
      }
    }

    &.simple {
      @media (--ds-breakpoint-lg) {
        grid-template-columns: 14% max-content;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

.title {
  margin: 0;
  color: var(--title-color);
  padding-bottom: var(--ds-spacing-fluid-xs);

  &.top {
    text-align: center;
  }

  &.left {
    text-align: left;

    @media (--ds-breakpoint-lg) {
      padding-right: var(--ds-spacing-fluid-xs);
    }

    &.simple {
      text-align: center;

      @media (--ds-breakpoint-md) {
        text-align: left;
      }

      @media (--ds-breakpoint-lg) {
        padding-bottom: 0;
      }
    }
  }
}

.quickLinksWrapper {
  --maximum-quick-links: 8;

  display: flex;
  width: 100%;
  justify-content: center;

  &.left {
    padding-left: 0;
  }

  & > [data-test='carousel'] {
    padding: 0; /* remove the padding from the carousel */

    @media (--ds-breakpoint-lg) {
      width: 100%;

      & [data-test='carousel-list'] {
        justify-self: center;
      }
    }
  }

  &.stack {
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: var(--ds-spacing-3xs);

    &.simple {
      & > li {
        flex-grow: 0;
        flex-shrink: 1;
        width: 79px;

        @media (--ds-breakpoint-sm) {
          width: 100%;
        }
      }

      @media (--ds-breakpoint-sm) {
        gap: var(--ds-spacing-sm);
      }

      @media (--ds-breakpoint-md) {
        flex-wrap: nowrap;
      }
    }

    &.advanced {
      & > li {
        flex-grow: 0;
        flex-shrink: 1;
        width: calc(100% / var(--maximum-quick-links));

        @media (--ds-breakpoint-xs) {
          min-width: 105px;
        }

        @media (--ds-breakpoint-md) {
          min-width: 194px;
        }

        @media (--ds-breakpoint-lg) {
          min-width: 105px;
        }
      }

      @media (--ds-breakpoint-lg) {
        width: 100%;
        flex-wrap: nowrap;
      }
    }
  }

  /* the quick link variants have nuanced differences in behaviour */
  &.simple {
    /* each simple quick link uses the default focus ring styling, so we create 2px of space for it by overriding the carousel's default padding-left of 0px  */
    & [data-test='carousel'] div {
      padding-left: var(--ds-spacing-5xs);
      scroll-padding-left: var(--ds-spacing-5xs);
    }

    /* center the simple quick link carousel on wide viewports */
    @media (--ds-breakpoint-md) {
      width: fit-content;
      max-width: 100%;
    }
  }

  &:has([data-test='carousel']) [data-test='carousel'] > *:first-child {
    /* reduce the padding between the bottom of the quick links and the scrollbar to match design spec */
    padding-bottom: var(--ds-spacing-xs);

    @media (--ds-breakpoint-lg) {
      padding-bottom: var(--ds-spacing-3xs);
    }

    /* on mobile screens the carousel is flush with the right hand edge of the screen. 
    We add margin-right to the scrollbar to match the margin-right of the last quick link so
    users have a clear idea that there is no more content to scroll to*/
    &::-webkit-scrollbar-track {
      margin-right: var(--ds-spacing-fluid-sm);
    }
  }

  /* on mobile screens the carousel is flush with the right hand edge of the screen.
  We add margin-right to the last quick link so users have a clear sense that there is no more content to scroll to */
  &:has([data-test='carousel']) [data-test='carousel'] div ol li:last-child {
    margin-right: var(--ds-spacing-fluid-sm);
  }

  /* 
  The :has pseudo-class below is allowing us to apply styles to the parent quickLinksWrapper if it contains a child li that is being hovered.
  In this case, we leave the opacity at a default of 1 for the quickLinksWrapper and the li that is hovered, and then target all the other li elements 
  that are not being hovered with a 0.6 opacity using the :not pseudo-class.

  The :focus-within pseudo-class then produces the same rollover effect as the :hover pseudo-class, but for keyboard users.
  */
  &.advanced {
    @media (--ds-breakpoint-sm) {
      & li {
        transition: opacity ease-out 0.3s;

        @media (prefers-reduced-motion: reduce) {
          transition: none;
        }
      }

      &:has(li:hover) {
        & li:not(:hover) {
          opacity: 0.6;
          transition: opacity ease-in 0.3s;

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }
      }

      &:has(li:focus-within) {
        & li:not(:focus-within) {
          opacity: 0.6;
          transition: opacity ease-in 0.3s;

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }
      }

      &:has([data-test='carousel'])
        [data-test='carousel']
        div
        ol
        li:last-child {
        margin-right: unset;
      }

      &:has([data-test='carousel']) [data-test='carousel'] > *:first-child {
        &::-webkit-scrollbar-track {
          margin-right: unset;
        }
      }
    }

    @media (--ds-breakpoint-lg) {
      width: 100%;

      & [data-test='carousel'] {
        & div ol {
          justify-content: center;
          width: 100%;
        }

        & div ol li {
          width: calc(100% / var(--maximum-quick-links));
        }
      }
    }
  }
}
