/**
Styled using container queries (https://developer.mozilla.org/en-US/docs/Web/CSS/@container)
rather than media queries because the style of the leaf depends on the selected layout and not just screen size.
(i.e. when toggling between list/grid view using the view-switcher on mobile PLPs the screen size remains the same,
but the space available for the leaf is much less with grid view, where some elements need to be hidden)
Browser support - https://caniuse.com/css-container-queries
*/

.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--ds-spacing-3xs);

  padding-top: var(--ds-spacing-xl);
  padding-bottom: var(--ds-spacing-xs);
  gap: var(--ds-spacing-2xs);
  container: leaf / inline-size;
  height: 100%;
  color: var(--ds-color-text-default);

  & :global(.wishlist-wrapper) {
    top: 0;
  }

  &.darkMode {
    color: var(--ds-color-text-inverse);
  }
}

.wishlistRow {
  display: flex;
  align-items: center;
  position: absolute;
  top: var(--ds-spacing-3xs);
  left: var(--ds-spacing-3xs);
  right: var(--ds-spacing-3xs);
  min-height: var(--ds-spacing-xl);
  justify-content: space-between;

  padding: 0;
  padding-bottom: var(--ds-spacing-5xs);

  & > span {
    margin-left: auto;
  }
}

.attributesRow {
  display: flex;
  align-items: center;

  & span:last-child {
    margin-right: 0;
  }
}

/** Hide the attributes (age, piece count, rating) if the leaf is smaller than 300px */
@container leaf (width < 200px) {
  .attributesRow {
    display: none;
  }
}

@media (--ds-breakpoint-md) {
  @container leaf (width < 200px) {
    .attributesRow {
      display: flex;
    }
  }
}

.attributeLabel {
  display: inline-flex;
  align-items: center;
  padding: 0;
  margin-right: var(--ds-spacing-2xs);
  gap: var(--ds-spacing-4xs);
  white-space: nowrap;
}

.titleRow {
  display: flex;
  margin: 0;
  margin-bottom: auto;
}

.extraPadding {
  padding-top: var(--ds-size-md);
}

.title {
  color: var(--ds-color-text-default);

  &:hover {
    text-decoration: underline var(--ds-color-text-default);
  }

  &.darkMode {
    color: var(--ds-color-text-inverse);
  }
}

.priceRow {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;

  &:focus-visible {
    box-shadow: 0 0 0 var(--ds-border-width-xs) var(--ds-color-focused-default) !important;
  }
}

.price {
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  margin-right: var(--ds-spacing-3xs);
}

.crossedOutPrice {
  composes: price;
  text-decoration: line-through;
}

.discountedPrice {
  composes: price;
  color: var(--ds-color-text-negative);
  margin-right: auto;
}

.insiderPrice {
  composes: price;
  color: var(--ds-color-core-purple-900);
}

.topItem {
  order: 1;
}

.middleItem {
  order: 2;
}

.bottomItem {
  order: 3;
}

.insiderLabel {
  display: flex;
  margin: 0;
  align-items: center;
}

.discountBadge {
  padding: 0;
  margin-left: var(--ds-spacing-5xs);
}

.discountPriceWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.priceDescription {
  color: var(--ds-color-text-subdued);

  &.darkMode {
    color: var(--ds-color-text-inverse);
  }
}

.actionRow {
  width: 100%;
}
