.wrapper {
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column-reverse;
  align-items: stretch;
  color: var(--text-color);
  background-color: var(--background);

  @media (--ds-breakpoint-md) {
    flex-direction: row;
  }
}

.trackPicker {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
  padding: var(--ds-spacing-lg);
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;

  @media (--ds-breakpoint-md) {
    width: 50%;
  }
}

.trackListWrapper {
  flex: 1;
}

.trackList {
  list-style: none;
  border-top: var(--ds-border-width-xs) solid var(--ds-color-border-inverse);
  overflow: auto;
  scroll-behavior: smooth;
  max-height: 26rem;
}

.trackLineItem {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: var(--ds-spacing-sm) var(--ds-size-sm);
  border-top: var(--ds-border-width-xs) solid var(--ds-color-border-inverse);
  width: 100%;
}

.trackLineItem.active {
  background-color: rgba(255, 255, 255, 0.1);
}

.trackLineIcon {
  background: rgba(0, 0, 0, 0.5);
  border: var(--ds-border-width-xs) solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: var(--ds-spacing-2xs);
}

.trackLineIcon.iconHidden {
  visibility: hidden;
}

.trackPickerTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: var(--ds-spacing-lg);
}

.trackListImageContainer {
  margin-right: var(--ds-spacing-lg);
  user-select: none;
  pointer-events: none;
}

.trackInfo {
  display: flex;
  flex-direction: column;
  margin-left: var(--ds-spacing-2xs);
  text-align: left;
}

.player {
  background-image: var(--background-mobile);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--ds-spacing-lg);

  @media (--ds-breakpoint-md) {
    background-image: var(--background-desktop);
    width: 50%;
    flex-grow: 1;
  }
}

.player.standalone {
  @media (--ds-breakpoint-md) {
    width: 100%;
  }
}

.playerContainer {
  width: 100%;
}

.player.standalone .playerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (--ds-breakpoint-md) {
    flex-direction: row;
  }
}

.player.standalone .trackAboutContainer {
  flex-direction: column;

  @media (--ds-breakpoint-md) {
    flex-direction: row;
  }
}

.player.standalone .trackDescription {
  @media (--ds-breakpoint-md) {
    padding-left: var(--ds-spacing-sm);
  }
}

.trackAboutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.trackAboutImage {
  user-select: none;
  pointer-events: none;
  width: 100%;
  flex-basis: max-content;
  min-width: min(100%, 284px); /* constant picked for audio player image size */
}

.trackDescription {
  padding: var(--ds-spacing-sm) 0;
  flex-grow: 1;

  @media (--ds-breakpoint-sm) {
    padding: 0;
    padding-top: var(--ds-spacing-sm);
  }
}

.title {
  display: block;
  margin-bottom: var(--ds-size-3xs);
}
