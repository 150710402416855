.wrapper {
  max-width: var(--st-dimension-max-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(
    --article-text-wrapper-background,
    var(--ds-color-layer-default)
  );
}

.articleTextContainer {
  padding: var(--st-sizing-md);
  width: 100%;
  max-width: calc(
    var(--article-text-container-width) + (2 * var(--st-sizing-md))
  );

  & > * {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.richTextContainer {
  text-align: var(--rich-text-alignment);

  & a {
    text-decoration: underline;

    &:active,
    &:hover {
      text-decoration: none;
    }
  }

  & h1 {
    font-weight: var(--st-font-weight-normal);
    font-size: var(--st-font-size-s3);
    line-height: var(--st-line-height-s3);

    @media (--breakpoint-small-min) {
      font-size: var(--st-font-size-s4);
      line-height: var(--st-line-height-s4);
    }
  }

  & ul,
  & ol {
    display: block;
    margin: var(--st-sizing-sm) 0;
    padding-left: var(--st-sizing-lg);
  }

  & li {
    list-style-position: var(--rich-text-list-style-position);
    margin-bottom: var(--st-sizing-xxs);
    font-size: var(--st-font-size-sm1);
    line-height: var(--st-line-height-sm1);

    @media (--breakpoint-small-min) {
      font-size: var(--st-font-size-s0);
      line-height: var(--st-line-height-s0);
    }
  }

  & ul li {
    list-style-type: disc;
  }

  & ol li {
    list-style-type: decimal;
  }

  & h2 {
    font-weight: var(--st-font-weight-bold);
    margin-top: var(--st-sizing-md);
    margin-bottom: var(--st-sizing-3xs);
    font-size: var(--st-font-size-s2);
    line-height: var(--st-line-height-s2);

    @media (--breakpoint-small-min) {
      font-size: var(--st-font-size-s3);
      line-height: var(--st-line-height-s3);
      margin-top: var(--st-sizing-lg);
      margin-bottom: var(--st-sizing-sm);
    }
  }

  & p {
    font-size: var(--st-font-size-sm1);
    line-height: var(--st-line-height-sm1);

    @media (--breakpoint-small-min) {
      font-size: var(--st-font-size-s0);
      line-height: var(--st-line-height-s0);
    }
  }

  &.darkMode {
    color: var(--ds-color-text-inverse);

    & a {
      color: var(--ds-color-text-inverse);
    }
  }
}
