.container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: var(--st-dimension-max-width);
  padding: var(--st-sizing-xl) 0;
  @media (--breakpoint-small-min) {
    padding: var(--st-sizing-3xl) 0;
  }
}

.title {
  font-weight: bold;
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
  top: 50%;
  left: 50%;
  user-select: none;
  white-space: nowrap;
}

.titleMobile {
  font-weight: bold;
  /* No appropriate skrøll token or shopper theme equivalent is available for the mobile title
     To ensure the font size is responsive and adjusts based on the viewport width we clamp
     the font size between 62px at 320px wide screens and 100px at 580px wide screens. */
  font-size: clamp(3.875rem, 1.161rem + 13.57vw, 6.25rem);
  line-height: 1;
  position: absolute;
  top: 50%;
  left: var(--st-sizing-4xs);
  color: rgba(255, 255, 255, 0.6);
  user-select: none;
  white-space: nowrap;
  text-orientation: mixed;
  writing-mode: vertical-rl;
}
.cta {
  border-radius: var(--st-sizing-lg);
  padding: var(--st-sizing-3xs) var(--st-sizing-ms);
  &:hover {
    text-decoration: none;
  }
}
.imageContainer {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  user-select: none;
}

.aspectRationBox {
  --desktop-image-height: 610;
  --desktop-image-width: 1600;
  --mobile-image-height: 704;
  --mobile-image-width: 600;
  --padding-ratio-mobile: calc(
    var(--mobile-image-height) / var(--mobile-image-width) * 100%
  );
  --padding-ratio-desktop: calc(
    var(--desktop-image-height) / var(--desktop-image-width) * 100%
  );
  padding-top: var(--padding-ratio-mobile);
  position: relative;
  width: 100%;
  @media (--breakpoint-small-min) {
    padding-top: var(--padding-ratio-desktop);
  }
}
